import React, { useEffect, useState } from "react"
import styled from "styled-components"
import logo from "../../images/loader/logo.svg"
import loading from "../../images/loader/loading.svg"


const LoaderSection = styled.section`
  height: 90vh;
  background-color: #484b50;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1000;
`
const Logo = styled.div`
    display: flex;
    height: 75px;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    & > img{
      padding-top:50px;
    }
    @media only screen and (max-width:768px){
      width: 50px;
      margin: auto;
    }
  `
const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    & *{
      font-size: 40px;
    }
      @media only screen and (max-width:768px){
        & *{
          font-size: 16px;
          font-weight: bold;
        }    
      }
  `
const LoadImg = styled.div`
  width: 350px;
  transition: 4s;
  position: relative;
  height: 70px;
  display: inline-flex;
  align-items: center;
  &:after{
    content: ' the numbers';
    height: 100%;
    position: absolute;
    width: fit-content;
    background: #484b50;
    right: 0;
    width: 73%;
    display: inline-flex;
    justify-content: flex-start;
    padding-left: 15px;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    @media only screen and (max-width:768px){
      width:40%;
      right: 15%;
    }
    @media only screen and (max-width:400px){
      padding-left: 5px;
    }
  }
  &:before{
    content: 'beyond ';
    z-index: 1;
    background: #484b50;
    position: absolute;
    left: 0;
    width: 27%;
    display: inline-flex;
    justify-content: flex-end;
    height: 100%;
    padding-right: 15px;
    display: flex;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px){
      // width:45%;
      left: 18%;
    }
    @media only screen and (max-width:400px){
       padding-right: 5px;
    }
  }
  @media only screen and (max-width:768px){
    
    & img{
    position: absolute;
        width: 44%;
    left: 26%;
     // width:40%;
     // left:30%
    }
  }

`

const Loader = () => {
  let [loadImgWidth, setLoadImgWidth] = useState(false)

  function typeWriter() {
    setLoadImgWidth(true)
  }

  useEffect(() => {
    setTimeout(typeWriter, 500)
  })

  return (
    <LoaderSection className={"loader"}>
      <Logo id="logo">
        <img src={logo} alt="Triple" />
      </Logo>
      <LoaderWrapper className="loading">
        <LoadImg className={loadImgWidth ? "loadImgWidth" : ""}>
          <img src={loading} alt="loading" />
        </LoadImg>
      </LoaderWrapper>
    </LoaderSection>
  )
}

export default Loader
